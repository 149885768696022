import useSWRMutation from 'swr/mutation'
import { Flex, Button, Section, Container, Text, Box, Avatar, Callout, Strong } from '@radix-ui/themes'
import { Layout, CreateRoot } from '@/components/Layout.jsx'
import { fetcher } from '@/utils/fetcher.js'
import { useValidateToken } from '../hooks/useValidateToken'
import notificationBell from '@/assets/images/shared/notification-bell.png'
import startBanner from '@/assets/images/start/start-banner-desktop.png'
import startBannerTablet from '@/assets/images/start/start-banner-tablet-fix.png'
import startBannerMobile from '@/assets/images/start/start-banner-mobile.png'
import decorativeCurve from '@/assets/images/start/decorative-curve-element.svg'
import communityClientLogo from '@/assets/images/shared/community-client-logo.svg'
import { usePageTitle } from '../hooks/usePageTitle'

const goToCreateNewAccount = () => {
  window.location.href = '/en/sign-up'
}

const goToSignin = () => {
  window.location.href = '/en/sign-in'
}

export default function Page () {
  usePageTitle()
  const { trigger: newsTrigger, data: newsData, error: newsError, isMutating: newsIsLoading } = useSWRMutation('/api/cmsItems/important-note', fetcher)
  const { isAuthenticated } = useValidateToken()

  if (isAuthenticated) {
    window.location.replace('/en/user')
    return <></>
  }

  if (!newsIsLoading && !newsData && !newsError) {
    newsTrigger({ method: 'GET' })
  }

  return (
    <Layout>
      <Flex
        direction={{
          initial: 'column',
          lg: 'row'
        }}
        justify='between'
        height='100dvh'
        overflow={{
          initial: 'auto',
          lg: 'hidden'
        }}
      >
        {/* Background banner image full screen sm screens and then left column lg screens */}
        <Box flexGrow='1' inset='0' position={{ initial: 'absolute', lg: 'relative' }}>
          <picture>
            <source srcSet={startBanner} media='(min-width: 75em)' />
            <source srcSet={startBannerTablet} media='(min-width: 40em)' />
            <img className='start-banner' src={startBannerMobile} alt='Two people doing zenful yoga' />
          </picture>
        </Box>
        <Flex
          flexGrow='1'
          direction='column'
          justify={{ initial: 'between', lg: 'start' }}
          width='100%'
          maxWidth={{ initial: 'none', lg: '692px', xl: '40%' }}
          height={{ initial: 'initial', lg: '100%' }}
          overflow={{ initial: 'initial', lg: 'auto' }}
        >
          {/* Callout */}
          <Section
            py={{ initial: '4', sm: '6' }}
            position='relative'
            className='z-1'
          >
            {newsData?.data.note && (
              <Flex px='4' justify='center' maxWidth={{ initial: '580px', lg: '380px' }} mx='auto'>
                <Box style={{ background: 'var(--accent-1)', borderRadius: 'var(--radius-4)', boxShadow: 'var(--shadow-2)' }}>
                  <Callout.Root size='2' variant='surface'>
                    <Flex gap='4'>
                      <Callout.Icon>
                        <Avatar
                          size='3'
                          src={notificationBell}
                          radius='full'
                          fallback='T'
                          width={40}
                        />
                      </Callout.Icon>
                      <Callout.Text size='3' style={{ color: 'var(--gray-12)' }}>
                        <Strong>Important news:</Strong> {newsData.data.note}
                      </Callout.Text>
                    </Flex>
                  </Callout.Root>
                </Box>
              </Flex>
            )}
          </Section>
          <Flex
            py={{
              initial: '0',
              sm: '120px',
              lg: '24px',
              xl: '40px'
            }}
            position='relative'
            className='z-1'
            overflowX={{ initial: 'hidden', lg: 'visible' }}
            flexGrow={{ initial: '0', lg: '1' }}
            direction='column'
            justify={{ initial: 'end', lg: 'center' }}
          >
            <img
              className='decorative-curve'
              src={decorativeCurve}
              alt=''
              role='presentation'
            />
            <Box
              className='start-account-box'
              width='100%'
              maxWidth={{
                initial: '100%',
                sm: '380px'
              }}
              mx='auto'
              style={{ background: 'white' }}
            >
              <Container py='5' px='4'>
                <Flex direction='column' align='center' gap='4'>
                  <Box>
                    <img
                      src={communityClientLogo}
                      alt='Community client'
                    />
                  </Box>
                  <Text align='center' size='8' weight='medium'>Let's get started!</Text>
                  <Text align='center' size='3' weight='medium' color='gray'>Ready to sign in or register? Let's quickly get you on your way to clear hearing.</Text>
                </Flex>
                <Box py='5'>
                  <Flex gap='2' direction='column'>
                    <Button size='4' variant='solid' onClick={goToSignin}>Sign-in</Button>
                    <Button size='4' variant='soft' onClick={goToCreateNewAccount}>Create new account</Button>
                  </Flex>
                </Box>
              </Container>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  )
}

CreateRoot(Page)
